/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'

import Header from './Header'
import Footer from './Footer'
import { useState } from 'react'

const Layout = ({ hideHeader, children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const [preloader, setPreloader] = useState(true)

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setPreloader(false)
    }, 1000)

    return () => clearTimeout(timeoutId)
  }, [])

  return (
    <>
      <div id="preloader" className={`preloader ${preloader ? '' : 'preloader--inactive'}`}></div>
      {!hideHeader && <Header siteTitle={data.site.siteMetadata.title} />}
      <div>
        <main>{children}</main>
      </div>
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  hideHeader: PropTypes.bool,
  hidePreloader: PropTypes.bool,
}

Layout.defaultProps = {
  hideHeader: false,
  hidePreloader: false,
}

export default Layout
