import { Link } from 'gatsby'
import React from 'react'
import ShelfieLogo from '../../images/shelfie-logo.svg'

const Footer = () => (
  <footer className="footer">
    <div className="container">
      <div className="footer-content">
        <Link className="d-block footer-logo" to="/">
          <img src={ShelfieLogo} alt="" />
          {/* © {new Date().getFullYear()} */}
        </Link>
        <ul>
          <li>
            <Link to="/">About</Link>
          </li>
          <li>
            <Link to="/terms">Terms of Use</Link>
          </li>
          <li>
            <Link to="/privacy">Privacy Policy</Link>
          </li>
        </ul>
      </div>
    </div>
  </footer>
)

export default Footer