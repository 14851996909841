import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import _ from 'lodash'
import ShelfieLogo from '../../images/shelfie-logo.svg'

const Header = ({ siteTitle }) => {
  useEffect(() => {
    window.addEventListener('scroll', _.debounce(toggleNavOnScroll, 100))

    const hamburgerButton = document.getElementById('hamburger')
    hamburgerButton.addEventListener('click', toggleHamburger)

    // Hide Responsive Nav on Item Click
    const navLink = document.querySelectorAll('a.nav-link')
    const navbarResponsive = document.getElementById('navbarResponsive')

    Array.from(navLink).forEach(link => {
      link.addEventListener('click', () => {
        if (navbarResponsive.classList.contains('show')) {
          navbarResponsive.classList.remove('show')
          toggleHamburger()
        }
      })
    })
    return () => {}
  }, [])

  const toggleNavOnScroll = () => {
    const scrollY = window.pageYOffset || document.body.scrollTop
    const navbar = document.getElementById('nav')
    const navbarContainer = document.getElementById('navContainer')
    // const heroHeight = document.getElementById('hero').clientHeight - 300;

    if (navbar && scrollY >= 100) {
      // navbar.classList.add('navbar-scrolling')
      navbarContainer.classList.add('navbar-scrolling')
    } else if (navbar && scrollY < 100) {
      // navbar.classList.remove('navbar-scrolling')
      navbarContainer.classList.remove('navbar-scrolling')
    }
  }

  const toggleHamburger = () => {
    const navbar = document.getElementById('nav')
    const hamburgerButton = document.getElementById('hamburger')
    const navbarResponsive = document.getElementById('navbarResponsive')

    navbar.classList.toggle('navbar--open')
    hamburgerButton.classList.toggle('open')
    navbarResponsive.classList.toggle('show')
  }

  return (
    <div id="navContainer" className="container-fluid navbar-container">
      <nav id="nav" className="navbar navbar-expand-lg navbar-light">
        {/* Suplias Logo */}
        <Link to="/" className="navbar-brand">
          <img className="navbar-logo" src={ShelfieLogo} alt="" />
        </Link>
        {/* Mobile Nav Buttons */}
        <ul className="navbar-nav ml-auto mr-3 d-lg-none d-block">
          <li className="nav-item">
            <a
              href="https://clearblue.weshelfie.com"
              className="btn btn-sm btn-primary d-none d-lg-block"
            >
              Login
            </a>
          </li>
        </ul>
        {/* Hamburger Button */}
        <div
          id="hamburger"
          className="navbar-toggler hamburger d-block d-lg-none collapsed"
          data-toggle="collapse"
          data-target="#navbarResponsive"
          aria-controls="navbarResponsive"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span></span>
          <span></span>
          <span></span>
        </div>
        {/* Responsive Nav */}
        <div className="collapse navbar-collapse" id="navbarResponsive">
          {/* <ul className="navbar-nav ml-auto mr-auto">
            <li className="nav-item">
              <a className="nav-link" href="/landing#features">
                Features
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/landing#products">
                Products
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/landing#download">
                Download
              </a>
            </li>
          </ul> */}
        </div>
        {/* Desktop Nav Buttons */}
        <ul className="navbar-nav d-none d-lg-block">
          <li className="nav-item">
            <a
              href="https://clearblue.weshelfie.com"
              className="btn btn-primary d-none d-lg-block"
            >
              Login
            </a>
          </li>
        </ul>
      </nav>
    </div>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
